import axios, { AxiosRequestHeaders } from 'axios'
const API_URL = 'https://skrind.com.ng/api/v1/'

// verify auth tokenn before any request

export const getAuthHeaders = (): AxiosRequestHeaders & any => {
  const token = '260|Scwb3Pg0f5gney7xErkSOxZorwr8lJOnRzx7L7J2'
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    common: {
      Authorization: `Bearer ${token}`,
    },
  }

  return headers
}

const default_axios_instance = axios.create({
  baseURL: API_URL,
  headers: getAuthHeaders(),
})

export default default_axios_instance
