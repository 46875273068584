
import { defineComponent, ref } from 'vue'
import FooterVue from './Footer.vue'
import NavBarVue from './NavBar.vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    NavBarVue,
    FooterVue,
  },
  setup() {
    return {}
  },
})
