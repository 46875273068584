import default_axios_instance from '@/controller/api/axios_config'
import { createStore } from 'vuex'
import { riskAssessments, healthTests } from '@/controller/utilities/data'
import { toast } from 'vue3-toastify'

export default createStore({
  state: {
    healthTests,
    kits: [],
    kitStores: [],
    kitDetails: {},
    currentRiskAssessments: [],
    allCountries: [],
    allStatesInSelectedCountry: [],
    allTownsnSelectedState: [],
    productsInCarts: [],
  },
  //
  getters: {
    productsInCarts(state) {
      return state.productsInCarts
    },
  },
  //
  actions: {
    //
    async getKits({ commit }: any) {
      let kits: any = []
      try {
        const response = await default_axios_instance.get(`test-types`)
        kits = response.data.data.data
      } catch (error) {
        console.log(error)
      }

      commit('UPDATE_KITS', kits)
    },
    //
    async getHealthTests({ commit }: any) {
      // let healthTests: any = []
      // try {
      //   const response = await default_axios_instance.get(`test-types`)
      //   healthTests = response.data.data.data
      // } catch (error) {
      //   console.log(error)
      // }

      // commit('UPDATE_HEALTH_TESTS', healthTests)
      return 'hello'
    },
    //
    async getKitStores({ commit }: any) {
      let kitStores: any = []
      try {
        const response = await default_axios_instance.get(`locate-kit-stores`)
        kitStores = response.data.data.data
      } catch (error) {
        console.log(error)
      }

      commit('UPDATE_KIT_STORES', kitStores)
    },
    //
    async getCurrentRiskAssessments({ commit }: any, testId: any) {
      let currentRiskAssessments: any = []
      riskAssessments.forEach((item: any) => {
        if (item.testId == testId) {
          currentRiskAssessments = item.data
        }
      })
      commit('UPDATE_CURRENT_ACTIVE_ASSESSMENTS', currentRiskAssessments)
    },
    //
    async getKitDetails({ commit }: any, testId: any) {
      let kitDetails: any = {}
      try {
        const response = await default_axios_instance.get(
          `test-types/${testId}`,
        )

        kitDetails = response.data.data
      } catch (error) {
        console.log(error)
      }

      commit('UPDATE_TEST_KIT', kitDetails)
    },
    //
    async getAllCountries({ commit }: any) {
      let allCountries: any = {}
      try {
        const response = await default_axios_instance.get(
          `countries-with-states`,
        )

        allCountries = response.data.data
      } catch (error) {
        console.log(error)
      }

      commit('UPDATE_ALL_COUNTRIES', allCountries)
    },
    //
    async getAllStatesInSelectedCountry({ commit }: any, countryId: any) {
      let allStatesInSelectedCountry: any = {}
      try {
        const response = await default_axios_instance.get(
          `countries/${countryId}/states`,
        )

        allStatesInSelectedCountry = response.data.data
      } catch (error) {
        console.log(error)
      }

      commit(
        'UPDATE_ALL_STATES_IN_SELECTED_COUNTRY',
        allStatesInSelectedCountry,
      )
    },
    //
    async getAllTownsInSelectedStates({ commit }: any, stateId: any) {
      let allTownsnSelectedState: any = {}
      try {
        const response = await default_axios_instance.get(
          `countries/${stateId}/states`,
        )

        allTownsnSelectedState = response.data.data
      } catch (error) {
        console.log(error)
      }

      commit('UPDATE_ALL_TOWNS_IN_SELECTED_STATE', allTownsnSelectedState)
    },
    //
    async addKitToCart({ commit, state }: any, kit: any) {
      const isKitInCart = state.productsInCarts.find(
        (eachKit: any) => eachKit.id === kit.id,
      )

      if (isKitInCart)
        return toast.error('Product already in cart', {
          autoClose: 2000,
          pauseOnHover: true,
        })

      toast.success('Product added to cart', {
        autoClose: 2000,
        pauseOnHover: true,
      })
      commit('ADD_KIT_TO_CART', kit)
    },
    //
    async removeKitFromCart({ commit, state }: any, product: any) {
      const products = state.productsInCarts.filter(
        (eachKit: any) => eachKit.id !== product.id,
      )

      commit('REMOVE_KIT_FROM_CART', products)
    },
  },

  //
  //
  //
  //
  //
  //
  mutations: {
    //
    UPDATE_KITS(state: any, kits: any) {
      return (state.kits = kits)
    },
    //
    UPDATE_HEALTH_TESTS(state: any, healthTests: any) {
      return (state.healthTests = healthTests)
    },
    //
    UPDATE_KIT_STORES(state: any, kitStores: any) {
      return (state.kitStores = kitStores)
    },
    //
    UPDATE_TEST_KIT(state: any, kitDetails: any) {
      return (state.kitDetails = kitDetails)
    },
    //
    UPDATE_CURRENT_ACTIVE_ASSESSMENTS(state: any, currentRiskAssessments: any) {
      return (state.currentRiskAssessments = currentRiskAssessments)
    },
    //
    UPDATE_ALL_COUNTRIES(state: any, allCountries: any) {
      return (state.allCountries = allCountries)
    },
    //
    UPDATE_ALL_STATES_IN_SELECTED_COUNTRY(
      state: any,
      allStatesInSelectedCountry: any,
    ) {
      return (state.allStatesInSelectedCountry = allStatesInSelectedCountry)
    },
    //
    UPDATE_ALL_TOWNS_IN_SELECTED_STATE(
      state: any,
      allTownsnSelectedState: any,
    ) {
      return (state.allTownsnSelectedState = allTownsnSelectedState)
    },
    //
    ADD_KIT_TO_CART(state: any, kit: any) {
      return state.productsInCarts.push(kit)
    },
    //
    REMOVE_KIT_FROM_CART(state: any, products: any) {
      console.log(state.productsInCarts)
      state.productsInCarts = products
      return console.log(state.productsInCarts)
    },
  },

  modules: {},
})
