
import { ref } from 'vue'
import IconsVue from '@/components/SVG/Icons.vue'

export default {
  name: 'SkrindFooter',
  components: { IconsVue },
  setup() {
    const footer_nav_links = ref([
      {
        title: 'Quick Links',
        sub_links: [
          {
            title: 'Kit Stores',
            link: '/kit-stores',
            isRoute: true,
          },
          {
            title: 'Health Tests',
            link: '/health-tests',
            isRoute: true,
          },
          {
            title: 'Buy Kits',
            link: '/kits',
            isRoute: true,
          },
          {
            title: 'Risk Assessments',
            link: '/risk-assessments',
            isRoute: true,
          },
        ],
      },
      {
        title: 'Products',
        sub_links: [
          {
            title: 'Hepatitis B',
            link: '/health-tests/1',
            isRoute: true,
          },
          {
            title: 'Hepatitis C',
            link: '/health-tests/2',
            isRoute: true,
          },
          {
            title: 'HIV 1/2',
            link: '/health-tests/3',
            isRoute: true,
          },
          {
            title: 'Malaria',
            link: '/health-tests/4',
            isRoute: true,
          },
        ],
      },
      {
        title: 'Company',
        sub_links: [
          {
            title: 'About Us',
            link: '/about',
            isRoute: true,
          },
          {
            title: 'Privacy Policy',
            link: '/privacy-policy',
            isRoute: true,
          },
        ],
      },
      {
        title: 'Contact',
        sub_links: [
          {
            title: 'getskrind@gmail.com',
            link: '',
            id: 'mail',
          },
          {
            title: 'WhatsApp ↗',
            link: '',
            id: 'whatsapp',
          },
        ],
      },
    ])

    const socials = ref([
      {
        title: 'Facebook',
        link: 'https://web.facebook.com/GetSkrind',
      },
      {
        title: 'Twitter',
        link: 'https://twitter.com/GetSkrind',
      },
      {
        title: 'Instagram',
        link: 'https://www.instagram.com/getskrind_/',
      },
      {
        title: 'LinkedIn',
        link: 'https://www.linkedin.com/company/getskrind/',
      },
    ])

    return {
      footer_nav_links,
      socials,
    }
  },
}
