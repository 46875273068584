<template>
  <div>
    <LayoutVue />
  </div>
</template>

<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import LayoutVue from './components/Layout/Layout.vue'

export default {
  name: 'HomePage',
  components: {
    LayoutVue,
  },
  setup() {
    const store = useStore()
    //
    const scrollToTop = () => window.scrollTo(0, 0)

    const getAll = async () => {
      await store.dispatch('getHealthTests')
      await store.dispatch('getKitStores')
      await store.dispatch('getKits')
    }

    onBeforeMount(() => {
      getAll()
      scrollToTop()
    })

    return {}
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

html,
body {
  font-family: 'Roboto', sans-serif;
}

#app {
  font-family: 'Roboto', sans-serif !important;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // font-family: 'proxima-nova', 'proxima nova', 'helvetica neue', 'helvetica',
  //   'arial', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    // color: #2c3e50;

    // &.router-link-exact-active {
    //   color: #42b983;
    // }
  }
}
</style>
