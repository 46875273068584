export const riskAssessments: any = [
  {
    testId: 1,
    data: [
      {
        id: 1,
        risk_test_id: 1,
        question: 'What is your gender?',
        question_reason:
          'We asked to know your gender so as to place properly your demography',
        options: [
          {
            id: 1,
            question_id: 1,
            option_value: 'Male',
            option_weight: 0,
          },
          {
            id: 2,
            question_id: 1,
            option_value: 'Female',
            option_weight: 0,
          },
          {
            id: 3,
            question_id: 1,
            option_value: 'Not necessary',
            option_weight: 0,
          },
        ],
      },
      {
        id: 2,
        risk_test_id: 1,
        question: 'Were you born between 1945 and 1975?',
        question_reason:
          "When you were born has an impact on a person's risk for viral hepatitis. The greatest number of people with hepatitis B are people born between 1945 and 2006 when Hepatitis B vaccination were not yet routinely administered, depending on your country of birth. This is due to exposure to a variety of different potential risk factors. It is recommended that all adults born between 1945 and 2006 have a one-time test for hepatitis B and possibly get vaccinated.",
        options: [
          {
            id: 4,
            question_id: 2,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 5,
            question_id: 2,
            option_value: 'No',
            option_weight: 0,
          },
        ],
      },
      {
        id: 3,
        risk_test_id: 1,
        question: 'Where were you born?',
        question_reason:
          "Place of birth has an impact on a person's risk for viral hepatitis. Country of birth has an implication on your risk of Hepatitis B as the national incidence of Hepatitis B is known to be higher in some regions such as Sub-Saharan Africa, Asia and America.",
        options: [
          {
            id: 6,
            question_id: 3,
            option_value: 'Asia or the Pacific Islands',
            option_weight: 2,
          },
          {
            id: 7,
            question_id: 3,
            option_value:
              'Africa (Add country and state for state incidence levels)',
            option_weight: 2,
          },
          {
            id: 8,
            question_id: 3,
            option_value: 'Europe or Australia\t',
            option_weight: 1,
          },
          {
            id: 9,
            question_id: 3,
            option_value: 'North America or South America (excluding the U.S.)',
            option_weight: 2,
          },
          {
            id: 10,
            question_id: 3,
            option_value: 'Not sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 4,
        risk_test_id: 1,
        question:
          'Have you ever received Blood transfusion or organ transplant before July 1992?',
        question_reason:
          'People could have become infected with viral hepatitis from contaminated blood, blood products, or organs before widespread screening of the blood supply began in 1992.',
        options: [
          {
            id: 11,
            question_id: 4,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 12,
            question_id: 4,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 13,
            question_id: 4,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 5,
        risk_test_id: 1,
        question:
          'Have you ever received Clotting factor concentrate before 1987?',
        question_reason:
          'People could have become infected with viral hepatitis from clotting factor concentrate before 1987.',
        options: [
          {
            id: 14,
            question_id: 5,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 15,
            question_id: 5,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 16,
            question_id: 5,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 6,
        risk_test_id: 1,
        question: 'Have you ever been diagnosed of Diabetes?',
        question_reason:
          'Certain medical conditions like diabetes can put a person at an increased risk for viral hepatitis or its associated complications.',
        options: [
          {
            id: 17,
            question_id: 6,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 18,
            question_id: 6,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 19,
            question_id: 6,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 7,
        risk_test_id: 1,
        question:
          'Have you ever been diagnosed of Sexually transmitted disease (STD)?',
        question_reason:
          'Certain medical conditions like STDs can put a person at an increased risk for viral hepatitis or its associated complications.',
        options: [
          {
            id: 20,
            question_id: 7,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 21,
            question_id: 7,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 22,
            question_id: 7,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 8,
        risk_test_id: 1,
        question: 'Have you ever been diagnosed of HIV infection/AIDS?',
        question_reason:
          'Certain medical conditions like HIV infections/AIDS can put a person at an increased risk for viral hepatitis or its associated complications.',
        options: [
          {
            id: 23,
            question_id: 8,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 24,
            question_id: 8,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 25,
            question_id: 8,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 9,
        risk_test_id: 1,
        question: 'Have you ever been diagnosed of Chronic liver disease ?',
        question_reason:
          'Certain medical conditions like chronic liver disease can put a person at an increased risk for viral hepatitis or its associated complications.',
        options: [
          {
            id: 26,
            question_id: 9,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 27,
            question_id: 9,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 28,
            question_id: 9,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 10,
        risk_test_id: 1,
        question: 'Have you ever been diagnosed of Clotting factor disorder?',
        question_reason:
          'Certain medical conditions like clotting factor disorder can put a person at an increased risk for viral hepatitis or its associated complications.',
        options: [
          {
            id: 29,
            question_id: 10,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 30,
            question_id: 10,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 31,
            question_id: 10,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 11,
        risk_test_id: 1,
        question:
          'Have you had an unprotected sex encounters with someone you ain’t sure of his/her status before?',
        question_reason:
          'Hepatitis B infection can happen when the blood, semen or vaginal fluids of an infected person gets into someone else’s body through sex.',
        options: [
          {
            id: 32,
            question_id: 11,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 33,
            question_id: 11,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 34,
            question_id: 11,
            option_value: 'Prefer not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 12,
        risk_test_id: 1,
        question: 'Do you have sexual encounters with other men?',
        question_reason:
          'Men who have sexual encounters with other men could be at greater risk for certain types of viral hepatitis.',
        options: [
          {
            id: 35,
            question_id: 12,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 36,
            question_id: 12,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 37,
            question_id: 12,
            option_value: 'Prefer not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 13,
        risk_test_id: 1,
        question: 'Have you ever injected drugs, even if just once?',
        question_reason:
          'Sharing injection drug equipment, even if just one time or many years ago, can put a person at increased risk for viral hepatitis.',
        options: [
          {
            id: 38,
            question_id: 13,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 39,
            question_id: 13,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 40,
            question_id: 13,
            option_value: 'Prefer not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 14,
        risk_test_id: 1,
        question:
          'Have you ever lived with someone who has been diagnosed with Hepatitis B?',
        question_reason:
          'Certain types of viral hepatitis, especially Hepatitis B, can be easily spread to unvaccinated household members.',
        options: [
          {
            id: 41,
            question_id: 14,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 42,
            question_id: 14,
            option_value:
              'Yes, I currently live with someone who is diagnosed with Hepatitis B',
            option_weight: 2,
          },
          {
            id: 43,
            question_id: 14,
            option_value:
              'Yes, I have previously lived with someone who was diagnosed with Hepatitis B',
            option_weight: 2,
          },
          {
            id: 44,
            question_id: 14,
            option_value:
              'Yes, but I am not sure which type of hepatitis they were diagnosed with',
            option_weight: 2,
          },
          {
            id: 45,
            question_id: 14,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
    ],
  },
  {
    testId: 2,
    data: [
      {
        id: 25,
        risk_test_id: 2,
        question: 'What is your gender?',
        question_reason:
          'We asked to know your gender so as to place properly your demography',
        options: [
          {
            id: 76,
            question_id: 25,
            option_value: 'Male',
            option_weight: 0,
          },
          {
            id: 77,
            question_id: 25,
            option_value: 'Female',
            option_weight: 0,
          },
          {
            id: 78,
            question_id: 25,
            option_value: 'Not necessary',
            option_weight: 0,
          },
        ],
      },
      {
        id: 26,
        risk_test_id: 2,
        question: 'Were you born between 1945 and 1975?',
        question_reason:
          "When you were born has an impact on a person's risk for viral hepatitis. The greatest number of people with hepatitis C are people born between 1945 and 1975 (>75%). This is due to exposure to a variety of different potential risk factors. It is recommended that all adults born between 1945 and 1975 have a one-time test for hepatitis C.",
        options: [
          {
            id: 79,
            question_id: 26,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 80,
            question_id: 26,
            option_value: 'No',
            option_weight: 0,
          },
        ],
      },
      {
        id: 27,
        risk_test_id: 2,
        question:
          'Did you have a blood transfusion, receive blood products or have an organ transplant before 1990?',
        question_reason:
          'Being transfused with blood or blood products before 1990 makes a person at an increased risk for viral hepatitis. The hepatitis C virus was discovered in 1989 and the first-generation screening test was implemented in 1990.',
        options: [
          {
            id: 81,
            question_id: 27,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 82,
            question_id: 27,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 83,
            question_id: 27,
            option_value: 'Not sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 28,
        risk_test_id: 2,
        question:
          'Have you undergone vaccination, medical procedures, surgery or received a blood transfusion in a country where hepatitis C is common?',
        question_reason:
          'In many countries around the world a single needle may have been used to vaccinate many different people, medical equipment may not have been properly sterilized or blood products may not have been screened.',
        options: [
          {
            id: 84,
            question_id: 28,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 85,
            question_id: 28,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 86,
            question_id: 28,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 29,
        risk_test_id: 2,
        question:
          'Have you ever injected or snorted drugs (even once)? Or do you have any tattoos or body piercings?',
        question_reason:
          'Shared drug equipment (including needles, straws etc.) can contain trace amounts of blood. While most tattoo and piercing studios adhere to high standards of infection control, some may inadvertently expose clients to hepatitis C via contaminated inks, needles or other equipment.',
        options: [
          {
            id: 87,
            question_id: 29,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 88,
            question_id: 29,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 89,
            question_id: 29,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 30,
        risk_test_id: 2,
        question:
          'Do you have a household contact of a person with hepatitis C?',
        question_reason:
          "Having a close household contact with hepatitis C viral infection and impacts on a person's risk for viral hepatitis",
        options: [
          {
            id: 90,
            question_id: 30,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 91,
            question_id: 30,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 92,
            question_id: 30,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 31,
        risk_test_id: 2,
        question:
          'To your knowledge, have you ever had a sexual partner with hepatitis C? Or have you had multiple sexual partners?',
        question_reason:
          'While hepatitis C is not commonly spread via sex, you could be at risk if your partner is infected with the virus and you engage in any sexual activities that might lead to bleeding.',
        options: [
          {
            id: 93,
            question_id: 31,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 94,
            question_id: 31,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 95,
            question_id: 31,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 32,
        risk_test_id: 2,
        question:
          'Have you shared personal care items such as razors, toothbrushes or nail clippers?',
        question_reason:
          'It only takes a trace amount of blood to transmit the hepatitis C virus, so if you have shared personal care items with someone who has hepatitis C, you could be at risk.',
        options: [
          {
            id: 96,
            question_id: 32,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 97,
            question_id: 32,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 98,
            question_id: 32,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 33,
        risk_test_id: 2,
        question:
          'Are you experiencing any unexplained signs or symptoms such as nausea, fatigue, reduced appetite, jaundice, dark urine or abdominal pain?',
        question_reason:
          'Hepatitis C often will not trigger symptoms until it reaches an advanced stage. Symptoms can be vague and often confused with other illnesses such as the flu.',
        options: [
          {
            id: 99,
            question_id: 33,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 100,
            question_id: 33,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 101,
            question_id: 33,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
      {
        id: 34,
        risk_test_id: 2,
        question:
          'Have you ever been employed in the medical/dental field involving direct contact with blood?',
        question_reason:
          'Hepatitis C often will not trigger symptoms until it reaches an advanced stage. Symptoms can be vague and often confused with other illnesses such as the flu.',
        options: [
          {
            id: 102,
            question_id: 34,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 103,
            question_id: 34,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 104,
            question_id: 34,
            option_value: 'Not Sure',
            option_weight: 1,
          },
        ],
      },
    ],
  },
  {
    testId: 3,
    data: [
      {
        id: 15,
        risk_test_id: 3,
        question: 'Do you consider yourself to be sexually active? ',
        question_reason:
          'Sexual intercourse without protection certainly predisposes to HIV.',
        options: [
          {
            id: 46,
            question_id: 15,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 47,
            question_id: 15,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 48,
            question_id: 15,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 16,
        risk_test_id: 3,
        question:
          'Have you ever engaged in an unprotected sex with someone you do not know his/her status?',
        question_reason:
          'Unprotected sex increases the risk of contact with body fluids (semen, vaginal fluid, or blood) and carries a significant risk for HIV.',
        options: [
          {
            id: 49,
            question_id: 16,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 50,
            question_id: 16,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 51,
            question_id: 16,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 17,
        risk_test_id: 3,
        question: 'Are you involved with multiple sex partners?',
        question_reason:
          'Multiple sex partners is a risk factor for getting infected with HIV.',
        options: [
          {
            id: 52,
            question_id: 17,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 53,
            question_id: 17,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 54,
            question_id: 17,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 18,
        risk_test_id: 3,
        question: 'Have you ever practiced anal or oral sex? ',
        question_reason:
          'Anal or oral sex, especially as a receptive carries a higher risk for HIV infection.',
        options: [
          {
            id: 55,
            question_id: 18,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 56,
            question_id: 18,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 57,
            question_id: 18,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 19,
        risk_test_id: 3,
        question:
          'Have you or your sex partner been ever diagnosed of any sexually transmitted diseases (STDs) before?',
        question_reason:
          'Among the varables for risk for HIV infection is previous diagnosis of STDs.',
        options: [
          {
            id: 58,
            question_id: 19,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 59,
            question_id: 19,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 60,
            question_id: 19,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 20,
        risk_test_id: 3,
        question: 'Have you ever been involved with commercial sex workers? ',
        question_reason:
          'Engagement in sex with commercial sex workers, especially without the use of condoms is associated with a higher risk of contracting HIV.',
        options: [
          {
            id: 61,
            question_id: 20,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 62,
            question_id: 20,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 63,
            question_id: 20,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 21,
        risk_test_id: 3,
        question: 'Are you involved in recreational drug usage?',
        question_reason:
          'Recreational drug use (RDU) is considered to result in sexual disinhibition and higher risk for HIV and other sexually transmitted infections (STIs).',
        options: [
          {
            id: 64,
            question_id: 21,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 65,
            question_id: 21,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 66,
            question_id: 21,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 22,
        risk_test_id: 3,
        question: 'Have you ever injected drugs before?',
        question_reason:
          'People who inject drugs are 22 times more at risk of HIV compared with the general population. This risk arises particularly from sharing needles or syringe to inject any substance (including steroids, hormones or silicone) which puts you at risk of HIV and other infections.',
        options: [
          {
            id: 67,
            question_id: 22,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 68,
            question_id: 22,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 69,
            question_id: 22,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 23,
        risk_test_id: 3,
        question:
          'Do you have sexual encounters with same sex (i.e. male to male or female to female)?',
        question_reason:
          'Homosexuality refers to an enduring tendency to form emotional, romantic and/or sexual attractions to people of the same sex. In particular is Sex between men, as it often involves anal intercourse, which carries a high risk of HIV transmission.',
        options: [
          {
            id: 70,
            question_id: 23,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 71,
            question_id: 23,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 72,
            question_id: 23,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
      {
        id: 24,
        risk_test_id: 3,
        question:
          'Have you ever received blood or blood products transfusion before?',
        question_reason:
          'People could have become infected with HIV from contaminated blood, blood products, or organs especially if not obtained from a standard certified blood bank.',
        options: [
          {
            id: 73,
            question_id: 24,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 74,
            question_id: 24,
            option_value: 'No',
            option_weight: 0,
          },
          {
            id: 75,
            question_id: 24,
            option_value: 'Preferred not to answer',
            option_weight: 1,
          },
        ],
      },
    ],
  },
  {
    testId: 4,
    data: [
      {
        id: 35,
        risk_test_id: 4,
        question:
          'Are you based in the tropical regions of the world including any of the following?',
        question_reason:
          'Malaria can found in more than 100 countries, mainly in tropical regions of the world, including the listed regions.',
        options: [
          {
            id: 105,
            question_id: 35,
            option_value: 'Large areas of Africa and Asia',
            option_weight: 2,
          },
          {
            id: 106,
            question_id: 35,
            option_value: 'Central and South America',
            option_weight: 2,
          },
          {
            id: 107,
            question_id: 35,
            option_value: 'Haiti and the Dominican Republic',
            option_weight: 2,
          },
          {
            id: 108,
            question_id: 35,
            option_value: 'Parts of the Middle East\t',
            option_weight: 2,
          },
          {
            id: 109,
            question_id: 35,
            option_value: 'Some Pacific Islands',
            option_weight: 2,
          },
        ],
      },
      {
        id: 36,
        risk_test_id: 4,
        question:
          'Have you recently traveled out of your location to a different place?',
        question_reason:
          'There is a significant risk associated with travel to malaria enedmeic countries or regions.',
        options: [
          {
            id: 110,
            question_id: 36,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 111,
            question_id: 36,
            option_value: 'No',
            option_weight: 0,
          },
        ],
      },
      {
        id: 37,
        risk_test_id: 4,
        question:
          'Are you aware of the risks of getting malaria before travelling?',
        question_reason:
          'Awareness of risk for malaria is important as patients should be aware of whether they are at risk of getting malaria before travelling',
        options: [
          {
            id: 112,
            question_id: 37,
            option_value: 'Yes',
            option_weight: 1,
          },
          {
            id: 113,
            question_id: 37,
            option_value: 'No',
            option_weight: 0,
          },
        ],
      },
      {
        id: 38,
        risk_test_id: 4,
        question:
          'If you are a recent traveler, is your travel location or country within any of the following listed regions of the world? \n 1) Large areas of Africa and Asia \n 2) Central and South America \n 3)Haiti and Dominican Republic \n 4)Parts of the Middle East \n 5) Some Pacific Islands',
        question_reason:
          'The risk of getting malaria in more than 100 countries within the tropical regions of the world, including countries within the listed regions, during travels is high.',
        options: [
          {
            id: 114,
            question_id: 38,
            option_value: 'Yes',
            option_weight: 1,
          },
          {
            id: 115,
            question_id: 38,
            option_value: 'No',
            option_weight: 0,
          },
        ],
      },
      {
        id: 39,
        risk_test_id: 4,
        question: 'Do you constantly sleep under a treated mosquito bed net?',
        question_reason:
          'Mosquito bite prevention is crucial to malaria prevention, and consistently sleeping under treated mosquito bed net helps ensure that.',
        options: [
          {
            id: 116,
            question_id: 39,
            option_value: 'Yes',
            option_weight: 0,
          },
          {
            id: 117,
            question_id: 39,
            option_value: 'No',
            option_weight: 2,
          },
        ],
      },
      {
        id: 40,
        risk_test_id: 4,
        question:
          'Have you recently slept outside of a treated mosquito bed net?',
        question_reason:
          'The risk of malaria is significantly higher in malaria endemic regions owing to mosquito bites when you do not sleep under treated mosquito bed net.',
        options: [
          {
            id: 118,
            question_id: 40,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 119,
            question_id: 40,
            option_value: 'No',
            option_weight: 0,
          },
        ],
      },
      {
        id: 41,
        risk_test_id: 4,
        question:
          'Do you consider your arms and legs adequately covered with clothes such as long sleeves or cover clothes while sleeping?',
        question_reason:
          'Covering the extremities including the arms and legs helps to avoid mosquito bites, however, many people behaviorally do not cover themselves while slepping, thus, making them to be at risk of mosquito bites and subsequent malaria infection.',
        options: [
          {
            id: 120,
            question_id: 41,
            option_value: 'Yes',
            option_weight: 0,
          },
          {
            id: 121,
            question_id: 41,
            option_value: 'No',
            option_weight: 2,
          },
        ],
      },
      {
        id: 42,
        risk_test_id: 4,
        question:
          'Do you regularly take active measures to protect against mosquito bites by using any insect repellants that help avoid mosquito bites?',
        question_reason:
          'There are insect repellants cream that helps reduce the chances of mosquito bites and the risk of getting malaria. None use of insect repellants therefore predisposes to a mosquito bite.',
        options: [
          {
            id: 122,
            question_id: 42,
            option_value: 'Yes',
            option_weight: 0,
          },
          {
            id: 123,
            question_id: 42,
            option_value: 'No',
            option_weight: 2,
          },
        ],
      },
      {
        id: 43,
        risk_test_id: 4,
        question:
          'Do you currently have any of the symptoms like fever and flu-like illness, including shaking chills, headache, muscle aches, and tiredness indicative of a malaria infection?',
        question_reason:
          'While these symptoms are not exclusive to malaria infection, they are common signs of malaria infection and are indicative of the need to quickly test to rule diagnose or rule out a malaria infection, especially when you live in or recently visited malaria endemic regions.',
        options: [
          {
            id: 124,
            question_id: 43,
            option_value: 'Yes',
            option_weight: 2,
          },
          {
            id: 125,
            question_id: 43,
            option_value: 'No',
            option_weight: 0,
          },
        ],
      },
      {
        id: 44,
        risk_test_id: 4,
        question:
          'Did you take a malaria test before taking a malaria treatment the last time you came down with malaria?',
        question_reason:
          'A definitive diagnosis with the help of laboratory tests to demonstrate malaria parasites or their components is to be made to help guide proper treatment by your doctors, and to ensure total clearance of the parasites from your body system.',
        options: [
          {
            id: 126,
            question_id: 44,
            option_value: 'Yes',
            option_weight: 1,
          },
          {
            id: 127,
            question_id: 44,
            option_value: 'No',
            option_weight: 2,
          },
        ],
      },
    ],
  },
]

export const healthTests: any = [
  {
    imageUrl: `https://res.cloudinary.com/skrind/image/upload/v1649633492/KC%20images/Hepatitis_B_Kyc_h5c79x.png`,
    id: '1',
    name: 'Hepatitis B',
    description: `Hepatitis B is a worldwide healthcare problem, especially in developing
        areas. An estimated one third of the global population has been infected
        with the hepatitis B virus (HBV). Complications from hepatitis B include
        progression to hepatocellular carcinoma (HCC) and, rarely, cirrhosis.`,
  },
  {
    imageUrl: `https://res.cloudinary.com/skrind/image/upload/v1649632982/KC%20images/Kyc_Hepatitis_fbyrze.png`,
    id: '2',
    name: 'Hepatitis C',
    description: `Hepatitis C is a potentially life-threatening liver disease caused by a
        virus that attacks the liver. It is contracted through blood-to-blood
        contact with an infected person which can happen in a variety of
        different ways.`,
  },
  {
    imageUrl: require(`@/assets/img/health-test/hiv-test.png`),
    id: '3',
    name: 'HIV/AIDs',
    description: ` Since its discovery in the early 1980s, Human Immunodeficiency Virus
        (HIV), the virus that causes AIDS, still remains one of the world’s most
        serious public health challenges. According to the World Health
        Organization, there were an estimated 37.7 million [30.2–45.1 million]
        people living with HIV at the end of 2020, over two thirds of whom (25.4
        million) are in the WHO African Region. In 2020, 680 000 [480 000–1.0
        million] people died from HIV-related causes and 1.5 million [1.0–2.0
        million] people acquired HIV.`,
  },
  {
    imageUrl: require(`@/assets/img/health-test/malaria-test.png`),
    id: '4',
    name: 'Malaria',
    description: `Discovered in the late 1800s, malaria continues to be a life threatening
        disease. According to the World Health Organization, in 2019, there were
        an estimated 229 million cases of malaria worldwide, the estimated
        number of malaria deaths standing at 409,000. Children aged under 5
        years are the most vulnerable group affected by malaria and in 2019,
        they accounted for 67% (274 000) of all malaria deaths worldwide.`,
  },
]
