
import { onBeforeMount, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import IconsVue from '@/components/SVG/Icons.vue'

export default {
  name: 'BaseNavbar',
  components: { IconsVue },
  setup() {
    const store = useStore()
    const scrollShadowBoolean = ref(true)
    const isDropdownOpen = ref(false)
    const productsInCarts = computed(() => store.getters.productsInCarts)

    const navLinks = ref([
      {
        route: '/home',
        title: 'Home',
      },
      {
        route: '/health-tests',
        title: 'Health Tests',
      },
      {
        route: '/kit-stores',
        title: 'Locate Kits',
      },
      {
        route: '/contact-us',
        title: 'Contact Us',
      },
    ])

    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        if (scrollShadowBoolean.value) scrollShadowBoolean.value = false
      } else {
        if (!scrollShadowBoolean.value) scrollShadowBoolean.value = true
      }
    }

    const currentActiveNavRoute = computed(() => useRoute().fullPath)

    window.addEventListener('scroll', () => handleScroll())

    onBeforeMount(() => {
      handleScroll()
    })

    return {
      scrollShadowBoolean,
      currentActiveNavRoute,
      navLinks,
      productsInCarts,
      isDropdownOpen,
    }
  },
}
