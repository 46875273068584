import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
//
const Home = () => import('@/views/Home/index.vue')
//
const About = () => import('@/views/About/index.vue')
//
const PrivacyPolicy = () => import('@/views/PrivacyPolicy/index.vue')
//
const HealthTests = () => import('@/views/HealthTests/index.vue')
const HealthTest = () => import('@/views/HealthTests/HealthTest/index.vue')
//
const SkrindKits = () => import('@/views/SkrindKits/index.vue')
//
const KitStores = () => import('@/views/KitStores/index.vue')
//
const Checkout = () => import('@/views/Checkout/index.vue')
//
const ContactUs = () => import('@/views/ContactUs/index.vue')

//
const RiskAssessments = () => import('@/views/RiskAssessments/index.vue')
const RiskAssessment = () =>
  import('@/views/RiskAssessments/RiskAssessment/index.vue')

//
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  //
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  //
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  //
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  //
  {
    path: '/kits',
    name: 'SkrindKits',
    component: SkrindKits,
  },
  //
  {
    path: '/health-tests',
    name: 'HealthTests',
    component: HealthTests,
  },
  {
    path: '/health-tests/:testId',
    name: 'HealthTest',
    component: HealthTest,
  },
  //
  {
    path: '/kit-stores',
    name: 'KitStores',
    component: KitStores,
  },
  //
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },
  //
  //
  {
    path: '/risk-assessments',
    name: 'RiskAssessments',
    component: RiskAssessments,
  },
  {
    path: '/risk-assessments/:testId',
    name: 'RiskAssessment',
    component: RiskAssessment,
  },
]

//
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
